

  @keyframes animated-shadow {
    0% {
      box-shadow: 0 0 0 rgba(255, 66, 66, 0);
    }
    50% {
      box-shadow: 0 0 40px 6px rgba(214, 214, 214, 0.5);
    }
    100% {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  
  .shadowed-element {
    animation: animated-shadow 2.5s infinite;
  }
  

  .coming{
    background-color: transparent;
    color: aliceblue;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 8px;
  }
  .coming:hover{
 
     transform: skew(100);
  }

  .pinksale {  
    animation:spin 8s linear infinite; 
}

@keyframes spin { 
    50% {  
        transform:rotate(25deg); 
    } 
    100% {   
        transform:rotate(0deg); 
    } 
}