 
 
 .Gen{ 
   background: linear-gradient(rgb(3, 6, 20,0.7), rgb(3, 6, 20,0.9)), url('../../src/Comp/img/ge.jpg')   center center;
   background-size: cover;
   width: 100%;
   height: 100vh;
 }
 
 
 .Generator{
   
  text-align: center;
   font-weight: 600;
   font-size: 44px;
 }
.yogesh{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

 .Imagination{
    background-color: transparent;
    border: 1px solid #fff;
    padding: 12px 12px ;
    border-radius: 8px;
 }
 .Boat{
    /* background-color: green; */
    color: #fff;
     padding: 9px 12px;
     border-radius: 8px;
     border: none;
     width: 100%;  
     border: 1px solid #fff;
     border-radius: 8px;
     font-size: 20px;
     background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #ff00ff57 );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; 
    transition: 0.2s ease-in-out ;
 }
 .Boat:hover{
  border-radius: 12px;
  transition: 0.2s ease-in-out ;
  border: 1px solid #ffff254d;
 }

 .immgg{
    margin-top: 20px;
    border: 1px solid #fff;
    border-radius: 7px;
 }
 .immgg img{
  width: 100%;
 
 }
 
 .immgg p{ 
    padding: 8px 10px;
    color: #b4b4b4;
    text-align: center;
 }


 .Try{
   background-color: transparent;
   color: #53DBFF;
   padding: 10px 30px;
    border: 1px solid red;
    border-radius: 8px;
 }

 .Try:hover{
   background-color: transparent;
   color: #53DBFF;
   padding: 10px 30px;
    border: 1px solid red;
    border-radius: 8px;
 }


 @media (max-width:768px) {
    .Boat{
        width: 60% !important;
        text-align: center !important;
        margin: 25px 0px 25px 0px;
    }
    .boat1{
        display:flex;
        justify-content: center;
    }
    .sp{
        padding: 45px 0px 63px 0px; 
    }
 }  
