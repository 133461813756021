*{
    ::-webkit-scrollbar {
        display: none;
    }
    scroll-behavior: smooth !important;
}



@keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  #cursor {
    animation: blink 1s infinite;
  }


/* .ASk{
    max-width: none;
    opacity: .7;
    color: #d4f6f9;
    letter-spacing: 4px;
    text-indent: 4px;
    text-transform: uppercase;
    text-shadow: 0 0 4px rgba(212,246,249,.47);
    background-color: transparent;
    border: 1px solid #d4f6f9;
    border-radius: 3px; 
    padding: 10px 42px; 
    font-size: 12px;
    font-weight: 600;
    transition: opacity .5s,transform .5s,box-shadow .5s;
    display: inline-block;
    box-shadow: 0 0 6px rgba(212,246,249,.27), inset 0 0 7px rgba(212,246,249,.88);
} */

.ASk{
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #FFFF25 , #FF55F3, #53DBFF,#FF00FF    );
    border-image-slice: 1; 

    background-color: transparent;
    color: #fff;
    padding: 12px 25px;
    transform: skew(-5deg); 
    width: 100%; 
    transition: 0.5s all ease-in-out;
}

.ASK:hover{
     transform: translate(-10px);
    transition: 0.5s all ease-in-out; 

}

.RiUser{ 
    color: #fff;
    background-color: #d753ff;
    padding: 8px;
    font-size: 35px;
    border-radius: 50%;
    font-weight: 600;
}

.textaar{

    width: 100%;
    padding: 16px;
    background: #111;
    color: #fff;
    border-radius: 5px;
    border: none;
    outline: none;
    -webkit-box-shadow: 0px 0px 24px -8px rgb(255, 255, 255);
-moz-box-shadow: 0px 0px 24px -8px rgb(255, 255, 255);
box-shadow: 0px 0px 24px 0px rgb(255, 255, 255);
    
}

.icon11{
    display: block !important;
}
.icoMobile{
    display: none !important;
}



@media (max-width:480px) { 
    .imghh{
       display: block !important;
    } 
    .chartmobile{
        margin-top: 80% !important;
    }
    .chartmobile h1{
        padding-top: 0% !important;

    }
   
}

@media (max-width:768px) { 
    .imghh{
       display: block !important;
       width: 20px;
    } 
    .chartmobile2{
        padding-top: 10% !important;
    }
    .chartmobile h1{
        padding-top: 40% !important;
     
    }

    .icon11{
        display: none !important;
    }
    .icoMobile{
        display: block !important;
        display: flex !important;
        justify-content: center !important;
        margin-top: 30px !important;
    }

    .ASk{
        width: 50% !important;
    }
     
}


@media (max-width:280px) {


    .chartmobile{
        margin: 0% !important;
    }
    .chartmobile h1{
        padding-top: 80% !important;
    }
    .imghh{
        width: 22px !important;

     } 

     .ArmorLogo{
        width: 100% !important; 
       margin-left:50% !important;
     }
}






.stage {
    width: 100vw;
    height: 100vh;
    perspective: 250px;
    z-index: -1;
    background-color: #000;
    animation: 10s infinite camera;
    position: fixed;
    top: 0;
    left: 0;
 }
 
 .stage div {
    box-sizing: border-box
 }
 
 .stage .floor {
    transform-origin: top;
    background-image: linear-gradient(#212121 .1vh,#0000 .1vh),linear-gradient(90deg,#212121 .1vh,#0000 .1vh);
    position: absolute;
    transform: rotateX(90deg);
    margin: 0 auto!important;
    top: 10%!important;
    left: 0!important;
    right: 0!important;
 }
 
 .stage .rightwall {
    transform-origin: 0;
    background-image: linear-gradient(#212121 .1vh,#0000 .1vh),linear-gradient(90deg,#212121 .1vh,#0000 .1vh);
    transform: translate(50%,-50%)rotateY(-90deg);
    height: 80%!important
 }
 
 .stage .leftwall {
    transform-origin: 100%;
    background-image: linear-gradient(#212121 .1vh,#0000 .1vh),linear-gradient(90deg,#212121 .1vh,#0000 .1vh);
    transform: translate(-150%,-50%)rotateY(90deg);
    height: 80%!important
 }
 
 .stage .center {
    background-image: linear-gradient(#212121 .1vh,#0000 .1vh),linear-gradient(90deg,#212121 .1vh,#0000 .1vh);
    transform: translate(-50%,-50%);
    height: 80%!important
 }
 
 .stage .ceiling {
    transform-origin: bottom;
    background-image: linear-gradient(#212121 .1vh,#0000 .1vh),linear-gradient(90deg,#212121 .1vh,#0000 .1vh);
    transform: rotateX(-90deg);
    margin: 0 auto!important;
    inset: auto 0 10%!important
 }
 
 .stage .threed {
    width: 80%;
    height: 4em;
    background-size: 1.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%
 }
 
 
 @keyframes runing {
     /* 0% {
         transform: rotate(23.6deg) rotateX(-23.6deg) rotateY(0deg);
     }
     100% {
         transform: rotate(23.6deg) rotateX(-23.6deg) rotateY(360deg);
     } */

     0% {
        transform: rotate(0.6deg) rotateX(20.6deg) rotateY(0deg);
    }
    100% {
        transform: rotate(0.6deg) rotateX(-48.6deg) rotateY(-360deg);
    }
 }
 .big {
     width: 100%;
    height: 100%;
     position: absolute;
 }
 .bigbox {
     width: 100%;
    height:100%;
     position: absolute;
 }
 .box {
     width: 22%;
     height: 38%;
     position: absolute;
     top: 130px;
     left:5%;
     border-radius: 50%;
     transform-style: preserve-3d;
     animation: runing 22s linear infinite;
 }
 .box div {
     width: 100%;height: 100%;
     position: absolute;
     top: 0;left: 0;
     color: #fff;
    border: 1px solid #bf00f4; 
     border-radius: 50% ; 
 }
 
 .box div:nth-child(1) {
     transform: rotateY(7.5deg);
 }
 .box div:nth-child(2) {
     transform: rotateY(15deg);
 }
 .box div:nth-child(3) {
     transform: rotateY(22.5deg);
 }
 .box div:nth-child(4) {
     transform: rotateY(30deg);
 }
 .box div:nth-child(5) {
     transform: rotateY(37.5deg);
 }
 .box div:nth-child(6) {
     transform: rotateY(45deg);
 }
 .box div:nth-child(7) {
     transform: rotateY(52.5deg);
 }
 .box div:nth-child(8) {
     transform: rotateY(60deg);
 }
 .box div:nth-child(9) {
     transform: rotateY(67.5deg);
 }
 .box div:nth-child(10) {
     transform: rotateY(75deg);
 }
 .box div:nth-child(11) {
     transform: rotateY(82.5deg);
 }
 .box div:nth-child(12) {
     transform: rotateY(90deg);
 }
 .box div:nth-child(13) {
     transform: rotateY(97.5deg);
 }
 .box div:nth-child(14) {
     transform: rotateY(105deg);
 }
 .box div:nth-child(15) {
     transform: rotateY(112.5deg);
 }
 .box div:nth-child(16) {
     transform: rotateY(120deg);
 }
 .box div:nth-child(17) {
     transform: rotateY(127.5deg);
 }
 .box div:nth-child(18) {
     transform: rotateY(135deg);
 }
 .box div:nth-child(19) {
     transform: rotateY(142.5deg);
 }
 .box div:nth-child(20) {
     transform: rotateY(150deg);
 }
 .box div:nth-child(21) {
     transform: rotateY(157.5deg);
 }
 .box div:nth-child(22) {
     transform: rotateY(165deg);
 }
 .box div:nth-child(23) {
     transform: rotateY(172.5deg);
 }
 .box div:nth-child(24) {
     transform: rotateY(180deg);
 }
 .box div:nth-child(25) {
     transform: translateY(-290px) rotateX(90deg) scale(.3);
 }
 .box div:nth-child(26) {
     transform: translateY(-275px) rotateX(90deg) scale(.41);
 }
 .box div:nth-child(27) {
     transform: translateY(-250px) rotateX(90deg) scale(.56);
 }
 .box div:nth-child(28) {
     transform: translateY(-220px) rotateX(90deg) scale(.685);
 }
 .box div:nth-child(29) {
     transform: translateY(-185px) rotateX(90deg) scale(.79);
 }
 .box div:nth-child(30) {
     transform: translateY(-150px) rotateX(90deg) scale(.87);
 }
 .box div:nth-child(31) {
     transform: translateY(-125px) rotateX(90deg) scale(.91);
 }
 .box div:nth-child(32) {
     transform: translateY(-100px) rotateX(90deg) scale(.945);
 }
 .box div:nth-child(33) {
     transform: translateY(-75px) rotateX(90deg) scale(.965);
 }
 .box div:nth-child(34) {
     transform: translateY(-50px) rotateX(90deg) scale(.975);
 }
 .box div:nth-child(35) {
     transform: translateY(-25px) rotateX(90deg) scale(.985);
 }
 .box div:nth-child(36) {
     transform: translateY(0px) rotateX(90deg) scale(1);
 }
 .box div:nth-child(37) {
     transform: translateY(25px) rotateX(90deg) scale(.985);
 }
 .box div:nth-child(38) {
     transform: translateY(50px) rotateX(90deg) scale(.975);
 }
 .box div:nth-child(39) {
     transform: translateY(75px) rotateX(90deg) scale(.965);
 }
 .box div:nth-child(40) {
     transform: translateY(100px) rotateX(90deg) scale(.945);
 }
 .box div:nth-child(41) {
     transform: translateY(125px) rotateX(90deg) scale(.91);
 }
 .box div:nth-child(42) {
     transform: translateY(150px) rotateX(90deg) scale(.87);
 }
 .box div:nth-child(43) {
     transform: translateY(185px) rotateX(90deg) scale(.79);
 }
 .box div:nth-child(44) {
     transform: translateY(220px) rotateX(90deg) scale(.685);
 }
 .box div:nth-child(45) {
     transform: translateY(250px) rotateX(90deg) scale(.56);
 }
 .box div:nth-child(46) {
     transform: translateY(275px) rotateX(90deg) scale(.41);
 }
 .box div:nth-child(47) {
     transform: translateY(290px) rotateX(90deg) scale(.3);
 }
 .box div:nth-child(48) {
     transform: translateY(300px) rotateX(90deg) scale(0);
 }
  
 
 ._number span{
    color: #fff;
    line-height: 35px; 
 }
 
 .Twi{
 background-color:transparent ;
 color: #fff;
 border: none;
 padding: 10px 30px;
 border:1px #fff dashed; 
 border-radius: 5px;
 font-size: 20px; 
 transition: 0.3s ease-in-out;
 
 }
 .Twi:hover{
    transition: 0.3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
 }
 .IG{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
 }

 .ArmorLogo{
    /* animation: animName 20s linear infinite; */
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
 }

 /* @keyframes animName {
    0%{
       transform: rotate(0deg);
      }
   100%{
       transform: rotate(360deg);
      }
   } */
   @keyframes up-down {
   0% {
    transform: scale(0.5);
}
100% {
    transform: scale(0.8);
}
}
   
 




@media (max-width:768px) {
 
    .box{    
        position: absolute !important;
      width: 86% !important;
     height: 40% !important; 
   border-radius: 50% !important;
   transform-style: preserve-3d !important;
   animation: runing  20s linear infinite !important;
   
  
  }
  .mobile{
      display: flex !important;
      justify-content: center !important;
  }

  
 /* .icoMobile{
    display: block !important;
    margin-top: 100% !important   ;
 } 
 .icon11{
    display: none !important;
 } */
}

/* .icoMobile{
    display: none !important;
 } 

 .icon11{
    display: block !important;
 } */

 

 @media (max-width:480px) { 
    .box{   
        position: absolute !important;
    width: 86% !important;
    height: 42% !important;
     border-radius: 50% !important;
     transform-style: preserve-3d !important;
     animation: runing 22s linear infinite !important; 
    }
 
    .mobile{
       display: flex;
       justify-content: center; 
    }
    .marquee{
       display: none !important;
    }
 
  
    .mobileLogo{
      position: absolute; 
      z-index: 0;
      opacity: 0.7;
      top: 10%;
    }
    .ArmorLogo{
        width: 65% !important;
        position: relative !important;
        left: 15% !important;
     }
     .guy{
        padding: 0% !important;
      }
    
 }
 

  
 @media (max-width:280px) {
     
    .box{  
   display: none !important;
  
  }
  .Twi{
    display: none !important;
  }
 
  .mobileLogo{
    display: flex !important;
    justify-content: left !important;
  }
  .ArmorLogo{
    width: 70%;
    margin: 0% !important;
  }
 }
  
  
