
 
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Mdl{
  background-color: #030614;
  border-radius: 15px !important;
   

}
.modal-content{
  background-color: transparent;
}
.presale_btn  {
  color: red !important; 
  border: 1px solid white;
  background: linear-gradient(to right, #53DBFF,
  #FF55F3, #FF55F3, #FF00FF ); 
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  padding: 5px 20px ;
  border-radius: 10px;

}
 
.lett span{
  margin: 5px 20px !important;
  
}

.lett h5{
  color: #fff !important;    
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #efefef;
  background: transparent url('Comp/img/icon\ \(1\).png') center / 1em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  opacity: .7;
  box-shadow: none;
}
 
.mobileImage{
  border-radius: 20px !important;
}

.Singapore{
  padding-top: 20%;
}
@media (max-width:768px) {
  .mobileImage{
    width: 100% !important;
    height: 50vh !important;
  }
  .Singapore{
    padding: 30px 0px 40px 5px !important;
  }
  .Singapore{
    line-height: normal !important;
  }
}
 