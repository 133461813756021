.lead{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #ff00ff57 );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; 
    font-size: 35px; 
}
.raja{
  transition: 0.8s ease-in-out;
    background-image: linear-gradient(0deg, #020005 0%, #650dd1 100%);
    border-radius: 50%;
 
} 

.founders{
    transition: 0.8s ease-in-out; 
   
}
.founders:hover{
    transition: 0.8s ease-in-out; 
    transform: scale(0.8);
}

.Ree{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #ff00ff57 );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; 
    font-size: 35px; 
}
 
.iicon{
    transition: 0.8s ease-in-out;  
}

.iicon:hover{
    transition: 0.8s ease-in-out; 
    transform: scale(0.8);
    border: 1px solid #53DBFF !important;
    color: #53DBFF !important;
}

@media (max-width: 768px) {
    
    .Avorak{
        text-align: justify !important;
    }
    
}