*{
    margin: 0%;
    padding: 0%;
    font-family: Arial, sans-serif;
    
 }
 
 
 .day{
     background-color: rgb(61, 61, 126);
     color: #fff;
     border-radius: 5px;
     padding: 6px ;
     text-align: center; 
     width: 100%;
     border: none;
     height: auto;
     display: inline-block;
     cursor: pointer;
     transition:.5s ease all; 
 }
 
 .day:hover{  
     width: 100%; 
     transition:.5s ease all; 
     transform: scale(1.1);
 }
 
 .hed {
     transition:.7s ease all;  
 }
 .hed:hover {
     transition:.8s ease all; 
     transform: scale(1.3);
 }
 
 .connet{
     background-color: rgb(61, 61, 126);
     color: #fff;
     border-radius: 5px;
     padding: 10px ;
     text-align: center; 
     width: 90%;
     border: none;
     height: auto;
     display: inline-block;
     cursor: pointer;
     transition:.5s ease all; 
 }
 .connet:hover{
     background-color: rgb(106, 75, 152);
     color: #fff; 
     
     transition:.5s ease all; 
     transform: scale(1.1);
 }
 
 
 
 
 .FRED{
     background-color: rgb(61, 61, 126);
     color: #fff;
     border-radius: 5px;
     padding: 10px ;
     text-align: center; 
     width: 100%;
     border: none;
     height: auto;
     display: inline-block;
     cursor: pointer;
     transition:.5s ease all; 
 }
  
 
 .Wallet{
     background-color: rgb(61, 61, 126);
     color: #fff;
     border-radius: 5px;
     padding: 10px 20px;
     text-align: center;  
     border: none;
     height: auto;
     display: inline-block;
     cursor: pointer;
     transition:.5s ease all; 
 }
 
 .KD1{
     transition: 0.5s;
 }
 
 .KD1:hover{
     transition: 0.5s;
     background-color: #2a243c !important;
     border-radius: 20px;
     color: #fff !important;
 }
 
 .WalletConnect:hover{
  background-color: bisque;
  border-radius: 8px;
  
 }
 .WalletConnect1:hover{
  background-color: bisque;
  border-radius: 8px;
  
 }