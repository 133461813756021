.filelabel2 {
    width: 90%;
    height: auto;
    border: 1px dashed #313131;
    border-radius: 5px;
    display: block;
    padding: 5px;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;
    margin: 10px;
}
.filelabel2 i {
    display: block;
    font-size: 30px;
    padding-bottom: 5px;
}
.filelabel2 i,
.filelabel2 .title2 {
    padding-top: 15px;
    margin-top: 100px;
    color:#313131 ;
    transition: 200ms color;
}

.filelabel2:hover i,
.filelabel2:hover .title2 {
  color: #1665c4;
}
#FileInput2{
    display:none;
}