.main{
    
}
 
#exampleModal{
    -webkit-box-shadow: 0px -1px 43px -4px rgba(224,224,224,1);
    -moz-box-shadow: 0px -1px 43px -4px rgba(224,224,224,1);
    box-shadow: 0px -1px 43px -4px rgba(224,224,224,1) ;
}

.nav-link{
    color: #fff ;
    margin: 0px 12px;
    font-size: 18px; 
    transition-duration: 1s, 1s;
}

.nav-link:hover{ 
    color: #53DBFf;
    margin: 0px 12px;
    font-size: 18px; 
    transition-duration: 1s, 1s; 
} 

.dd{ 
    background: linear-gradient(to right, #53DBFF,
    #FF55F3, #FF55F3, #FF00FF ); 
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.Nick{ 
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 35px;
}

.navbar-toggler{
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #FFFF25 , #FF55F3, #53DBFF,#FF00FF    );
    border-image-slice: 1; 
}

.Privacy{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}



.Paradigm{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    
}


.Dol{ 
    background: linear-gradient(to right, #FFFF25,
    #53DBFF, #FFFF25, #53DBFF );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.sst{
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #FFFF25 , #FF55F3, #53DBFF,#FF00FF    );
    border-image-slice: 1; 
}   

.Humanity6{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}


.text-rainbow-animation { 
    background-image: 
      linear-gradient(to right,#fff,#fff,#fff, #FFFF25,
      #FF55F3, #53DBFF, #FF00FF); 
    -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;  
    animation: rainbow-animation 35s linear infinite;
  }
  
  @keyframes rainbow-animation {
      to {
          background-position: 800vh;
      }
  }

.pd4{
   background-image: url('../../Comp/img/Bg.png');
   background-repeat: no-repeat;
   background-position: center center;
   background-size: contain;
   height: 90vh;  
   margin-top: 50px;  
}
 
.nav-item span{
    transition-duration: 1s, 1s;
    border-bottom: 1px solid transparent; 

}

.Investing{
    font-size: 30px; 
    letter-spacing: 0.5px;
    font-weight: 400;
}

.military{
    letter-spacing: 1.5px;
    line-height: 30px;
}
 
/* .nav-item span:hover{
    
  transition-duration: 2s, 2s;   
  background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF ); 
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;  
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(to right, #FFFF25 , #FF55F3, #53DBFF,#FF00FF    );
    border-image-slice: 1; 
} */

/* .bag{
    background-image: url('../../Comp/img/test.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    height: 80vh;     
    width: 100%;
} */
.Humanity12{
    /* background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF ); 
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;   */
    font-size: 45px; 
}

.sp{
    padding: 100px 0px 80px 0px;
}

.mahakal{

    transform: skew(-10deg); 
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #FFFF25 , #FF55F3, #53DBFF,#FF00FF    );
    border-image-slice: 1; 
}
.mahakal1{

    transform: skew(10deg); 
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #FFFF25 , #FF55F3, #53DBFF,#FF00FF    );
    border-image-slice: 1; 
}
.QQ{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.Desk{
    display: block !important;
}
.Mob{
    display: none !important;
}
 

.Hiring{
 background-color: transparent;
 padding: 13px 22px;
 color: #fff;
 border: 1px solid rgb(135, 135, 135);
 width: 100%;
 border-radius: 10px;
 text-align: center;
 font-size: 20px; 
 transition-duration: 1s, 1s;
}

.Hiring:hover{  
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #ff00ff57 );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; 
    font-size: 20px; 
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #FFFF25 , #FF55F3, #53DBFF,#FF00FF    );
    border-image-slice: 1; 
    transform: skew(10deg); 
    transition: 2000ms;
    transition-duration: 1s, 1s;
    border-radius: 20px;
   }

   .Hiring2{
 background-color: transparent;
 padding: 12px 22px;
 color: #fff;
 border: 1px solid rgb(255, 255, 255);
 width: 100%;
 border-radius: 10px;
 text-align: center;
 font-size: 20px; 
 transition-duration: 1s, 1s; 
} 
   .Hiring2:hover{ 
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #ff00ff57 );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; 
    -webkit-border-radius: 15px !important;
    padding: 12px 22px;
    color: #ffffff;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #FFFF25 , #FF55F3, #53DBFF,#FF00FF    );
    border-image-slice: 1; 
    transform: skew(-10deg); 
    transition: 2000ms;
    width: 100%;
    border-radius: 10px !important;
    text-align: center;
    font-size: 20px; 
    transition-duration: 1s, 1s; 
} 


.twit{ 
   transition-duration: 0.5s, 0.5s;
   transition: transform 0.3s ease-out;
 }

   .twit:hover{
   border-radius: 10px !important; 
   transition-duration: 0.5s, 0.5s;
   color: #08a7d3;
   border: 1px solid #08a7d3 !important;
   transform: rotate(360deg);
}
     .gajju{
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    filter: grayscale(0);
   }

   .gajju:hover{
    cursor: pointer;
    transition: all 0.6s ease-in-out;
    filter: grayscale(100);
   }  

   .unorder li{
    transition: all 0.6s ease-in-out;
    cursor: pointer;
   }

   .unorder li:hover{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition: all 0.6s ease-in-out;
   }


   .Utility{ 
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition: all 0.6s ease-in-out;
    font-weight: 600;
   }

   /* .Token{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition: all 0.6s ease-in-out;
    
   } */
   .Software{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition: all 0.6s ease-in-out;
    font-weight: 600; 
   }

   .Subscribe{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition: all 0.6s ease-in-out;
    font-weight: 600;
    font-size: 18px;

   }
   .Subscribe1{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition: all 0.6s ease-in-out; 
   }
   .accordion-button.collapsed {
    background: rgb(0, 0, 236)
  }
  
  .accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    
}
.collapsed {
    color: rgb(255, 255, 255) !important;
}

 

 
 .Available{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition: all 0.6s ease-in-out; 
    font-size: 45px;
 }
 .cardsvg{ 
    border: 2px solid #ff00ffb6;
    border-radius: 10px;
    width: 100%;
    transition: 0.7s ease-in-out; 
 }

 .cardsvg:hover{
    transition: 0.7s;
    transform: scale(0.94);
  box-shadow: 0 0 40px 6px rgba(214, 214, 214, 0.5); 
}


 .cardsvg2{ 
    width: 100%; 
    border: 2px solid #ff00ffb6;
    border-radius: 10px; 
    transition: 0.7s ease-in-out; 
 }
 .cardsvg2:hover{
    transition: 0.7s;
    transform: scale(0.94);
  box-shadow: 0 0 40px 6px rgba(214, 214, 214, 0.5); 
}


 .Icon1{
      /* background-color: #FFFF25 ;  */
      border: 1px solid #FFFF25;
    border-radius: 50%;
    transition: all 0.6s ease-in-out; 
 }
 .Icon3{
      /* background-color: #53DBFF ;  */
    border: 1px solid #53DBFF;  
    border-radius: 50%;
    transition: all 0.6s ease-in-out; 
 }
 .Icon5{
      /* background-color: #53DBFF ;  */
      border: 1px solid #53DBFF;
    border-radius: 50%;
    transition: all 0.6s ease-in-out; 
 }
 .Icon4{
      /* background-color: #FF00FF ;  */
      border: 1px solid #FF00FF;
    border-radius: 50%;
    transition: all 0.6s ease-in-out; 
 }
 .Icon6{
      /* background-color: #FFFF25 ;  */
      border: 1px solid #FFFF25;
    border-radius: 50%;
    transition: all 0.6s ease-in-out; 
 }

 .gtr{ 
    animation: up-down 0.8s ease-in-out infinite alternate-reverse both !important; 
 }

 @keyframes up-down{
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-2px);
    } 
 } 

 
  






 .TRYNOW{
    background-color: transparent;
    padding: 12px 22px; 
    -webkit-border-radius: 15px !important;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 8px;
    text-align: center;
    font-size: 20px; 
    transition-duration: 1s, 1s; 

 }

 .TRYNOW:hover{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #ff00ff57 );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; 
    -webkit-border-radius: 15px !important;
    padding: 12px 22px;
    color: #ffffff;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #FFFF25 , #FF55F3, #53DBFF,#FF00FF    );
    border-image-slice: 1; 
    transform: skew(-10deg); 
    transition: 2000ms;  
    text-align: center;
    font-size: 20px; 
    transition-duration: 1s, 1s; 

 }
.dropdown-item:hover{
  background-color: #08a7d3;
  border-radius: 8px;
}

.ChatBox{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #ff00ff57 );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; 
}

.vv5{
    transition: 0.7s;
 filter: grayscale(0);
}
.vv5:hover{
    transition: 0.7s;
 filter: grayscale(150);
}
.coinbase{
    transition: 0.7s; 
}
.coinbase:hover{
    transition: 0.7s; 
    transform: scale(1.1);
}
.Binance{
    transition: 0.7s; 
}
.Binance:hover{
    transition: 0.7s; 
    transform: scale(1.1);
}
.vv1{
    transition: 0.7s;
 filter: grayscale(0);
}
.vv1:hover{
    transition: 0.7s;
 filter: grayscale(150);
 transform: scale(1.1);
}

.vv2{
    transition: 0.7s;
 filter: grayscale(0);
}
.vv2:hover{
    transition: 0.7s;
 filter: grayscale(150);
 transform: scale(1.1);
}
.vv3{
    transition: 0.7s;
 filter: grayscale(0);
}
.vv3:hover{
    transition: 0.7s; 
    filter: grayscale(150);
    transform: scale(1.1);
}
.vv5{
    transition: 0.7s;
 filter: grayscale(0);
}
.vv5:hover{
    transition: 0.7s; 
    filter: grayscale(150);
    transform: scale(1.1);
}
.j9{
    transition: 0.7s;
 filter: grayscale(0);

}
.j9:hover{
    transition: 0.7s;
    filter: hue-rotate(90deg);
    transform: scale(1.1);
}
.iframe{
    -webkit-box-shadow: 0px -1px 43px -4px rgba(224,224,224,1);
    -moz-box-shadow: 0px -1px 43px -4px rgba(224,224,224,1);
    box-shadow: 0px -1px 43px -4px rgba(224,224,224,1);
    
}
.j91{
    transition: 0.5s;
}
.j91:hover{
    transition: 0.5s;
    transform: scale(1.1);
}


@media (max-width:480px) {
    .Investtor{
        padding: 0% !important;
        margin-top: 10px;
     }
     
     .pd4{
        
        padding-top: 0% !important;
        padding-top: 30% !important;
    }
}

@media (max-width:280px) {
 
    .navbar-brand img{ 
        width: 150px !important;
    }
    .hed3{
        font-size: 30px !important;
     }
     
    .hed4{
        font-size: 20px !important;
     } 
     .investment{
        padding-top: 65% !important;
     }

     .iframe iframe{
        height: 200px !important;
         
     }


}


@media (max-width:768px) {
    /* .investment{
        padding-top: 20% ;
     } */

     .Investtor{
        padding-top: 9rem;
     }

    .j9{
        padding-top: 0% !important;
        margin-top: 0% !important;
    }
    .j91{
        padding-top: 0% !important;
        margin-top: -8% !important;
        transform: scale(1.1);
    }
    

    .TRYNOW{
        width: 100% !important;
    }

  
    .Hiring{
        width: 100%;
    }
.Desk{
    display: none !important;
}
.Mob{
    display: block !important;
}

    .pd4{
        
        padding-top: 0% !important;
        padding-top: 20% !important;
    }

    .hed{
         font-size: 42px !important;
         padding: 0px 5px;

    }

    .hed1{
         font-size: 22px !important;
         padding: 0px 5px; 
         letter-spacing: 0; 
    }
    br{
        display: none !important;
    }

    .Whitepaper{

        display: flex;
        justify-content: center;

    }
    .hed3{
        padding-top: 0% !important;
        padding-top: 10% !important;
    }

    .rot{
        display: none;
     }
     .hed{
        padding-top: 0% !important;
        padding-top: 50px;
     }
     .hed3{
        font-size: 35px !important;
     }

     .Roadmap{
        font-size: 40px !important;
     } 

     .pd4{
        background-image: url('../../Comp/img/Bg.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 65vh !important;  
        margin-top: 50px;  
     }
     .demo5{
          
        padding: 0% !important;
        padding: 15px;
          
     }
     .demo51{ 
        padding-top: 10px; 
     }  
     .demo55{
        width: 60% !important;
     }
     .s4{
        margin: 0% !important;
     }
     .smm{
        display: none !important;
     }
     .Sv{
        padding-top: 0% !important;
        margin-top: 20px;
     }
     .Investing{
        padding-top: 0% !important;
        margin-top:  0px;
     }

     .kl{
        padding: 0% !important;
        margin-top: 0% !important;
     }
     .kl1{ 
        margin-top: 0% !important;
        margin-top: 2% !important;
     }
     .kl2{ 
       
        margin-top: 0% !important;
     }
     .kl3{ 
        padding-top: 0% !important; 
       
     }

     .stt1{
        margin-top: 0% !important;
     }
     
   .Humanity12  {
      margin-top: 0% !important;
      padding-top: 0% !important; 
   }
   .devel{
    padding-top: 30px !important;
   }

   .SafeGPT{
 padding: 0% !important;

   }

   .fttp{
    padding-top: 0% !important;
   }

   .Investing{
    letter-spacing: 0px !important;
    text-align: center; 
    text-align: left;
    line-height: auto !important;
    font-size: 22px !important;
   }

   .gloster{
    padding-top: 0% !important;
   }

   .Whitepaper button{ 
    padding: 0% !important;
    padding: 3px 0px 0px 18px !important; 
   }
   .coinbase{
    width: 50% !important;
   }
   .Binance{
 
    margin: 10px 0px 0px 0px !important;
   }
   .vv5{
    width: 70% !important;
    
   }
   .iframe{
    margin: 0% !important;
   }

   .iframe iframe{
   height: 250px !important; 
}
.Ig{
    padding: 0% !important;
}

.Billgets{
    padding: 0% !important;
}

.cardsvg2{
    height: auto !important;
    padding-bottom: 15px !important;
}
 
.cardsvg{
    height: auto !important;
    padding-bottom: 15px !important;
}
 

}
 



 
 

.demo{
    transition: 0.5s; 
}


.demo:hover{
    transition: 0.5s;
    transform: scale(1.1);
}

.Stack{
    background-color: transparent;
    padding: 10px 30px;
    border: 1px solid rgb(255, 255, 255);
   
    transition: all 0.3s ease-in-out; 
    font-weight: 500;
    border-radius: 10px;
    font-size: 20px;
}
.Stack:hover{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #ff00ff57 );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;  
    padding: 10px 30px;
    color: #ffffff;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #FFFF25 , #FF55F3, #53DBFF,#FF00FF    );
    border-image-slice: 1; 
    transform: skew(-10deg); 
    transition: 2000ms;  
    text-align: center;
    font-size: 20px; 
    transition-duration: 1s, 1s;
}
.taktak{
    
    font-size: 34px;
    padding-left: 0%;
}
.ArmorAI10{
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #FF00FF
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 18px;
    font-weight: 500;
    
}


.gradient-border-pseudo {
    position: relative;
    padding: 10px 20px;
    background: #fff;
    margin: 5px;
    border-radius: 5px;
    border: none;
  }
  
  .gradient-border-pseudo::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px; 
    padding: 15px;
    border: 2px solid transparent;
    background: linear-gradient(to right, #FFFF25,
    #FF55F3, #53DBFF, #ff00ff57 );
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

















